.slideshow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.slide {
  margin: 0 auto;
  display: none;
  animation-name: fade;
  animation-duration: 1.5s;
}

.slide img {
  max-width: 100%;
  height: auto;
}

.active {
  display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dots {
  text-align: center;
  margin-top: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #fff;
}
