.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: var(--header-height);
  background-image: url('https://res.cloudinary.com/dhnhzlu0g/image/upload/v1701039878/liberty-nutrition-system/home-images/header-wallpaper_lrusxh.jpg');
  background-size: cover;
  display: flex;
  z-index: 3;
  transition: top 0.3s;
}

.header img {
  display: flex;
  flex-direction: row;
  margin-left: 20rem;
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
}

@media (max-width: 850px) {
  .header img {
    margin: 0 auto;
  }
}

@media (max-width: 815px) {
  .header img {
    margin: 0 auto;
  }
}

@media (max-width: 700px) {
  .header img {
    max-width: 65%;
    max-height: auto;
    margin: 0 auto;
  }
}
