.founder-bio {
  width: 400px;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-image: url('https://res.cloudinary.com/dhnhzlu0g/image/upload/v1701039878/liberty-nutrition-system/home-images/founder-wallpaper_nu1amn.jpg');
  border: 1px solid #000000;
  background-size: cover;
  overflow: auto;
}

@media (max-width: 1500px) {
  .founder-bio {
    margin-top: 2rem;
    width: 80%;
  }
}

@media (max-width: 850px) {
  .founder-bio {
    padding: 1rem;
  }

  .founder-text {
    font-size: 14px;
  }

  .founder-content img {
    width: 60%;
    border: 2px solid #000000;
  }
}

.founder-title {
  margin: 0 auto;
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.founder-content {
  display: flex;
  justify-content: center;
}

.founder-content img {
  max-width: 100%;
  border: 5px solid #000000;
}

.founder-text {
  align-items: center;
  text-align: left;
  margin-top: 2rem;
}
