.email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.form {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-image: url('https://res.cloudinary.com/dhnhzlu0g/image/upload/v1701648155/liberty-nutrition-system/sign-up-form-background_udexle.webp');
  border: 2px solid #000000;
  border-radius: 5px;
  align-items: center;
}

.form h4 {
  font-size: 20px;
  font-weight: 300;
  padding: 15px;
  color: #000000;
}

.form .user-info {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.user-info input {
  background-color: #fff;
  color: #000000;
  font-family: inherit;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.user-info label {
  margin-left: 2.1rem;
}

.submit-button {
  background-color: #87ceeb;
  color: #fff;
  font-size: 17px;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: solid 1px #fff;
  width: 45%;
  margin: 0 auto;
  margin-top: 1rem;
}

.submit-button:hover,
.submit-button:focus {
  background: #eee;
  color: 000;
  outline: 0;
}

.user-info input,
.next-button {
  margin-bottom: 5px;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
}

.error-text {
  color: #000000;
}

.email-card {
  margin-top: 10rem;
}

.submitted {
  padding: 10px;
  text-decoration: none;
}

.back {
  margin-top: 2rem;
  margin-left: 3rem;
  margin-bottom: -1rem;
  text-decoration: none;
  color: #000000;
  width: 2%;
}

.back:hover {
  color: #fff;
}

.container {
  max-width: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
