.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.form {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-image: url('https://res.cloudinary.com/dhnhzlu0g/image/upload/v1701648155/liberty-nutrition-system/sign-up-form-background_udexle.webp');
  border: 1px solid #000000;
  border-radius: 5px;
  align-items: center;
}

.form h4 {
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}

.form .user-info {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.user-info input {
  background-color: #fff;
  color: #000000;
  font-family: inherit;
  letter-spacing: 1px;
  margin: 0 auto;
}

.user-info input::placeholder {
  font-size: 14px;
  color: #c0b4b4;
}

.login-button {
  background-color: #87ceeb;
  color: #fff;
  font-size: 17px;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: solid 1px #fff;
  width: 55%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.login-button:hover,
.login-button:focus {
  background: #eee;
  color: 000;
}

.user-info input,
.login-button {
  margin-bottom: 10px;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 60px;
  font-size: 14px;
}

.suggestions {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.suggestions .forgot {
  color: #000000;
  cursor: pointer;
}

.remember {
  margin-right: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.forgot {
  text-decoration: none;
  text-align: center;
}

.forgot:hover,
.forgot:focus {
  color: #6f6a6a;
  outline: 0;
  transition: background-color 2s ease-out;
}

.signup {
  font-family: inherit;
  font-size: 14px;
  background-color: #4169e1;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
}

.signup:hover,
.signup:active {
  background: #0056b3;
}

.error {
  color: #000000;
}
