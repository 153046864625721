.banner {
  position: sticky;
  background-color: aqua;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media (max-width: 850px) {
  .banner {
    max-height: 5.1rem;
  }
}

.exclamation {
  margin-right: 10px;
}

.bannerButton {
  border: none;
  margin-right: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}

.bannerButton:hover,
.bannerButton:active {
  background-color: #eee;
}
