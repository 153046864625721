.navDesktop {
  margin: 0 auto;
  margin-top: 1rem;
}

.nav {
  display: flex;
  flex-direction: row;
  margin-right: 50rem;
}

@media (max-width: 1100px) {
  .nav {
    margin-right: 40rem;
  }
}

.nav-link {
  align-items: center;
  color: #000000;
  font-size: 16px;
  padding: 10px;
  margin-right: 15px;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: aqua;
  text-wrap: nowrap;
  text-transform: none;
}

.nav-link:hover {
  background: #eeeeee;
}

.products {
  position: relative;
  display: flex;
  flex-direction: column;
}

.products.active .products-submenu {
  display: block;
}

.products-submenu {
  position: absolute;
  width: 12rem;
  top: 70%;
  display: none;
  background-color: aqua;
  text-align: start;
  border: 1px solid #fff;
  border-radius: 2px;
  z-index: 1;
}

.products-submenu .nav-link {
  padding: 10px 20px;
  color: #333;
  display: block;
  transition: background 0.3s ease;
  background-color: aqua;
  margin: 0 auto;
  text-wrap: wrap;
  border-radius: 2px;
}

.products-submenu .nav-link:hover {
  background: #f0f0f0;
}

.productsButton {
  font-family: inherit;
  border: none;
  cursor: pointer;
}
