.cartButton {
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  margin-left: 40px;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: #4169e1;
  text-wrap: nowrap;
}

.cartButton:hover,
.cartButton:active {
  background-color: rgb(194, 194, 194);
}

.cartButton span {
  margin: 0 0.5rem;
}

.badge {
  background-color: #fff;
  border-radius: 30px;
  padding: 0.15rem 0.5rem;
  color: #000000;
}

.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
