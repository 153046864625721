.banner {
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 2;
  transition: top 0.3s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
