.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media (max-width: 1500px) {
  .headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .leftPane {
    margin-right: 0;
  }
}
