.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 850px) {
  .contact-info {
    font-weight: bold;
    font-size: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.phone-number {
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}

.email {
  display: flex;
  flex-direction: row;
}

.phone-number,
.email {
  font-weight: bold;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.phone-number i,
.email i {
  margin-right: 10px;
}
