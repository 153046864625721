.nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  transition: left 0.3s ease;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.menu-button {
  width: var(--header-height);
  background: none;
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  margin-right: auto;
  display: block;
  position: absolute;
  left: 0;
  z-index: 2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 999;
  transition: opacity 0.3s ease;
}

.open {
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: translateX(0);
  z-index: 1000;
  transition: transform 0.3s ease;
}

.closed {
  transform: translateX(-300px);
  z-index: 1000;
  transition: transform 0.3s ease;
}

.visible {
  display: block;
  opacity: 1;
}

.nav-closeButton {
  top: 10px;
  z-index: 3;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.nav-closeButton:hover {
  background: #eeeeee;
}

.nav-links {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100vh;
  width: 250px;
  background: #4169e1;
  transform: translateX(-300);
  transition: transform 0.3s ease;
  overflow-y: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 12px 15px;
  background: transform 0.2s;
}

.nav-link-open {
  transform: translateX(0);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.nav-link > i {
  margin-right: 15px;
}

.nav-link:hover {
  background: #eeeeee;
}

.plus-symbol {
  margin-left: 75px;
  cursor: pointer;
}

.rotate-clockwise {
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.rotate-counterclockwise {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.nav-products {
  cursor: pointer;
}

.nav-submenu {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.submenu-open {
  max-height: 1000px;
  transition: max-height 0.3s ease;
}

.submenu-closed {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: none;
  transition: max-height 0.3s ease;
}

.nav-submenu a {
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 10px 15px;
  text-decoration: none;
}

.nav-submenu a:hover {
  background: #eeeeee;
}

.badge {
  background-color: #fff;
  border-radius: 30px;
  padding: 0.15rem 0.5rem;
  color: #000000;
  margin-left: 5.5rem;
}
