.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 22rem;
  max-width: 100%;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: lavender;
  padding: 20px;
  z-index: 1001;
  border-radius: 5px;
  max-height: 80vh;
  overflow-y: auto;
}

.submitted {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: lavender;
  padding: 20px;
  z-index: 1001;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  overflow-y: auto;
}

@media (max-width: 850px) {
  .container {
    width: 300px;
    height: 500px;
  }

  .submitted {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: lavender;
    padding: 20px;
    z-index: 1001;
    border-radius: 5px;
    width: 300px;
    height: 150px;
    overflow-y: auto;
  }
}

.title {
  text-align: center;
}

.survey-title {
  text-align: center;
  margin-bottom: 1rem;
}

.survey {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.survey label {
  display: flex;
  flex-direction: row;
}

.survey input[type='checkbox'] {
  margin-left: 0px;
  width: 16px;
  height: 16px;
  margin-right: 15px;
}

.category {
  font-weight: bold;
}

.button {
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #eee;
  color: #000000;
  border: none;
}

.button:hover,
.button:active {
  background-color: gray;
}

.close-button {
  cursor: pointer;
}

.close-button:hover,
.close-button:active {
  color: #fff;
}

h3 {
  text-decoration: none;
}
