.footer {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #4169e1;
  color: #fff;
  padding: none;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 14px;
  font-weight: normal;
}
