.empty {
  justify-content: center;
  display: flex;
}

.title {
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

@media (max-width: 1200px) {
  table {
    margin-top: 10px;
  }

  th,
  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  th {
    text-align: left;
  }

  td {
    text-align: left;
    border-bottom: 1px solid #dddddd;
    padding: 8px;
  }

  tr {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  th:first-child,
  td:first-child {
    display: table-cell;
  }
}
