.inquiry {
  margin-bottom: 5px;
}

@media only screen and (max-width: 1200px) {
  td[data-label='ID'],
  td[data-label='Nombre'],
  td[data-label='Correo'],
  td[data-label='Teléfono'],
  td[data-label='Estado'] {
    display: flex;
    justify-content: space-between;
  }

  td[data-label='ID'] {
    background-color: #f2f2f2;
  }

  tr {
    display: block;
    margin-bottom: 15px;
  }

  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}
