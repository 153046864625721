.form {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 50px;
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 20px;
  background-image: url('https://res.cloudinary.com/dhnhzlu0g/image/upload/v1701648155/liberty-nutrition-system/sign-up-form-background_udexle.webp');
  background-size: cover;
  margin-bottom: 20px;
}

@media (max-width: 850px) {
  .form {
    max-width: 250px;
  }
}

h3 {
  text-align: center;
  text-decoration: underline;
}

label {
  margin-bottom: 4px;
  text-align: justify;
}

input {
  width: 75%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.button-container {
  text-align: center;
}

.submit-button {
  font-family: inherit;
  font-size: 16px;
  background-color: #87ceeb;
  color: #fff;
  border: solid 1px #fff;
  text-transform: uppercase;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button:hover,
.submit-button:active {
  background: #eee;
}

.submit-button:disabled,
.submit-button:disabled:hover,
.submit-button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border: #ccc;
  cursor: not-allowed;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #000000;
}

.back {
  margin-top: 2rem;
  margin-bottom: -1rem;
  text-decoration: none;
  color: #000000;
  width: 2%;
}

.back:hover {
  color: #fff;
}

.container {
  max-width: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.card {
  margin-top: 10rem;
  max-height: fit-content;
  max-width: fit-content;
}
