.card {
  margin: 1rem auto;
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  width: 90%;
  max-width: 50rem;
}

@media (max-width: 850px) {
  .card {
    max-width: 20rem;
  }
}
