.cart-item {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 10px;
  margin-right: 40px;
  padding: 10px;
  border: 1px solid #a9a9a9;
}

@media (max-width: 850px) {
  .cart-item {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    margin-right: 0px;
    padding: 2px;
    border: none;
    border-bottom: 1px solid #000000;
  }

  .cart-item-details-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item h4 {
    text-align: center;
  }
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.cart-item-details-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cart-item h4,
.cart-item span {
  margin: 0;
}

.cart-item h4,
.cart-item span:first-child {
  margin-bottom: 5px;
}

.buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 1px;
}

.buttons button {
  color: #000000;
  background-color: cyan;
  border: 1px solid rgb(140, 140, 140);
  border-radius: 10px;
  gap: 1px;
}

.buttons button:hover,
.buttons button:active {
  cursor: pointer;
  background-color: rgb(255, 236, 236);
}
